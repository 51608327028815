<template>
  <CContainer fluid class="datatable-container">
    <Loader :is-processing="state.isLoading" />
    <!-- Start Modals -->
    <!-- User avatar modal -->
    <CModal
      backdrop="static"
      :visible="!!state.activeAvatar"
      :close-on-backdrop="false"
    >
      <div class="pt-4 px-4 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="state.activeAvatar = null"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <div v-if="state.activeAvatar" class="pb-3 px-3">
        <img class="img-fluid" :src="state.activeAvatar" alt="" />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- Archive message -->
    <CModal
      backdrop="static"
      :visible="!!state.showArchivedUsersModal"
      :close-on-backdrop="false"
    >
      <template #header-wrapper>
        <div
          class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
        >
          <div
            class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
            @click="
              ;(state.showArchivedUsersModal = null), resetResponseMessages()
            "
          >
            <span class="close-icon"></span>
          </div>
        </div>
      </template>
      <div class="px-3 pt-3 pb-1">
        <div>
          {{
            state.reqResponse.message !=
            "Invalid error from CUS, please contact with administrator"
              ? state.reqResponse.message
              : "This change may take 24 hours to execute. Check tomorrow for this request to be updated. If no change, please contact the help desk."
          }}
        </div>
        <div class="text-center mt-3">
          <button
            @click="
              ;(state.showArchivedUsersModal = null), resetResponseMessages()
            "
            class="btn p-3 bg-gradient-blue text-white ms-2"
          >
            Okay
          </button>
        </div>
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <!-- End Modals -->
    <CRow class="mt-5">
      <CCol>
        <div
          class="table-tabs-header mb-2 bg-white d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <div class="me-4">
              <div
                v-for="(tab, index) in state.tableTabs"
                :key="index"
                class="table-tab"
                :class="{ active: tab.value === state.activeTableTab.value }"
              >
                {{ tab.label }}
              </div>
            </div>

            <div v-if="authTypes" class="d-flex justify-content-center">
              <div
                v-for="(authType, index) in authTypes"
                :key="index"
                class="auth-type-icon"
              >
                <span
                  v-if="authType.icon"
                  :class="'flaticon-' + authType.icon"
                  :title="authType.title"
                >
                  <span v-if="authType.html" v-html="authType.html" />
                </span>
              </div>
            </div>

            <div
              v-if="isActiveSyncVisible && state.isActiveSyncBtn"
              class="d-flex flex-column"
              @click="syncUsers(synchronization.id)"
            >
              <CLoadingButton
                :spinner="state.isLoadingBtn"
                :loading="state.isLoadingBtn"
                class="user-table-custom-btn d-flex m-auto px-4 py-2"
              >
                <span class="align-content-center">{{
                  state.syncButtonText
                }}</span>
              </CLoadingButton>
              <span class="fw-bold font-xs mt-1"
                >Last sync:
                {{ synchronization && synchronization.sync_finish_at }}</span
              >
            </div>
            <div
              v-if="!state.isActiveSyncBtn && isSuperAdmin"
              class="d-flex flex-column"
            >
              <CLoadingButton
                :spinner="true"
                :loading="true"
                :disabled="true"
                class="user-table-custom-btn disabled d-flex m-auto px-4 py-2"
              >
                <span class="align-content-center">{{
                  state.syncButtonText
                }}</span>
              </CLoadingButton>
            </div>
          </div>

          <div class="actions d-flex align-items-center">
            <div
              class="lh-normal cursor-pointer text-accent-blue-light ms-2 ms-sm-0"
              @click="
                handleUsers(state.pagination.activePage, false, false, true)
              "
            >
              <i class="fi flaticon-download me-1"></i>
              <span class="d-none d-md-inline-block">Download CSV</span>
            </div>

            <div
              class="d-flex align-items-center search-field mb-0 ms-2 ms-md-4"
            >
              <CInputGroup class="w-auto">
                <div class="input-group-prepend">
                  <span class="input-group-text lh-normal"
                    ><div><i class="flaticon-magnifying-glass"></i></div
                  ></span>
                </div>
                <CFormInput
                  placeholder="Search"
                  v-model="state.columnSearchKeys.globalQuery"
                  @input="searchByQuery($event.target.value)"
                >
                </CFormInput>
              </CInputGroup>
            </div>
          </div>
        </div>
        <div class="status-guides-container d-flex flex-wrap">
          <div
            class="d-flex align-items-center actions ps-0 ps-sm-3 mb-3 mb-sm-0"
          >
            <input
              v-if="!provideUsersByApiOnly"
              type="checkbox"
              class="me-2"
              :checked="
                state.selectedUsers.length === users?.length && users.length > 0
              "
              @change="selectUsers($event)"
            />

            <CButton
              v-if="!provideUsersByApiOnly"
              class="me-2 p-small bg-gradient-blue gradient-btn"
              @click="inviteBulk()"
            >
              <span class="btn bg-white px-3 py-1 text-uppercase"
                ><b class="text-graident-blue">Reset Password</b></span
              >
            </CButton>

            <CButton
              v-if="!provideUsersByApiOnly"
              class="me-2 p-small bg-gradient-blue gradient-btn"
              @click="archive()"
            >
              <span class="btn bg-white px-3 py-1"
                ><b class="text-graident-blue text-uppercase">Archive</b></span
              >
            </CButton>

            <HelpCenterButton
              v-if="!provideUsersByApiOnly"
              width="18"
              height="18"
              classes="me-2 mt-2"
              content-key="login_type_info"
              :is-old="true"
            />
            <CCheckBox
              label="Student Number"
              :model-value="state.filterParams.student_sis_id"
              @change="changeSNFilter()"
              name="Student number"
              class="custom-checkbox"
              :inline="true"
              :custom="true"
            />
          </div>

          <per-page-selector
            :action="setPerPage"
            :pagination="state.pagination"
          />
        </div>

        <CAlert
          v-if="state.reqResponse.message"
          class="mt-1 w-100 mx-3"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>

        <DataTable
          class="datatable remove-first-th-border bg-white"
          :items="users"
          :fields="activeDataFields"
          :column-filter="true"
          :sorter="{ external: true }"
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #select="{ item }">
            <td class="mw-auto">
              <input
                v-if="!provideUsersByApiOnly"
                v-model="state.selectedUsers"
                type="checkbox"
                :value="item.id"
              />
            </td>
          </template>

          <template #new="{ item }">
            <td class="mw-auto">
              <button
                v-if="!item.is_synced"
                class="px-3 py-1 btn btn-unarchive d-flex align-items-center border-0"
                @click="recreateUser(item)"
              >
                <span class="flaticon-back-arrow"></span>
                <span class="ms-1 text-uppercase fw-bold">Sync</span>
              </button>

              <b
                v-if="getUserStatus(item)"
                class="px-3 py-2 bg-gradient-blue reverse text-white fw-bold text-uppercase"
                :style="`border-radius: 13.5px;font-size:11px;display: inline-block; width: ${
                  getUserStatus(item).length > 7 ? '100px' : 'auto'
                }`"
                >{{ getUserStatus(item) }}</b
              >
            </td>
          </template>

          <template #student_sis_id="{ item }">
            <td>
              <div>
                {{ item && item.student_sis_id ? item.student_sis_id : "" }}
              </div>
            </td>
          </template>

          <template #first_name="{ item }">
            <td
              :class="{
                'text-accent-blue-light fw-bold cursor-pointer': item.avatar
              }"
              @click="showAvatar(item.avatar)"
            >
              {{ item.first_name }}
            </td>
          </template>

          <template #last_name="{ item }">
            <td
              :class="{
                'text-accent-blue-light fw-bold cursor-pointer': item.avatar
              }"
              @click="showAvatar(item.avatar)"
            >
              {{ item.last_name }}
            </td>
          </template>

          <template #first_name-filter>
            <input
              v-model="state.columnSearchKeys.first_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #last_name-filter>
            <input
              v-model="state.columnSearchKeys.last_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #email="{ item }">
            <td>
              {{ item.email ? item.email : "" }}
            </td>
          </template>

          <template #email-filter>
            <input
              v-model="state.columnSearchKeys.email.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #action="{ item }">
            <td class="mw-auto">
              <CDropdown>
                <CDropdownToggle
                  color="primary"
                  class="btn btn-edit me-2 px-2 py-1"
                  >...</CDropdownToggle
                >
                <CDropdownMenu class="user-actions">
                  <CDropdownItem
                    v-if="!provideUsersByApiOnly"
                    class="btn btn-edit me-2 px-2 py-1 action"
                    @click="onViewUser(item)"
                    ><i class="flaticon-eye me-2" /> View
                  </CDropdownItem>
                  <CDropdownItem
                    v-if="!provideUsersByApiOnly"
                    class="btn btn-edit me-2 px-2 py-1 action"
                    @click="editUser(item)"
                  >
                    <i class="flaticon-edit me-2" /> edit
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </td>
          </template>

          <template #status="{ item }">
            <td>
              <b
                class="limit-status no-target d-block text-center w-full"
                :class="item.status ? 'active' : 'inactive-gray'"
                >{{ item.status ? "active" : "inactive" }}</b
              >
            </td>
          </template>

          <template #role_id="{ item }">
            <td>
              {{
                item.current_role_in_school
                  ? item.current_role_in_school.name
                  : ""
              }}
            </td>
          </template>

          <template #role_id-filter>
            <input
              v-model="state.columnSearchKeys.role_id.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #grade_year="{ item }">
            <td>
              {{ item.grade_year ? item.grade_year : "" }}
            </td>
          </template>

          <template #grade_year-filter>
            <input
              v-model="state.columnSearchKeys.grade_year.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #auth_type="{ item }">
            <td>
              {{ getAuthType(item.auth_type) }}
            </td>
          </template>

          <template #auth_type-filter>
            <input
              v-model="state.columnSearchKeys.auth_type.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #web_last_login_at="{ item }">
            <td class="text-center">
              {{ item.web_last_login_at ? item.web_last_login_at : "None" }}
            </td>
          </template>
          <template #web_last_login_at-filter>
            <input
              v-model="state.columnSearchKeys.web_last_login_at.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #mobile_last_login_at="{ item }">
            <td class="text-center">
              {{
                item.mobile_last_login_at ? item.mobile_last_login_at : "None"
              }}
            </td>
          </template>
          <template #mobile_last_login_at-filter>
            <input
              v-model="state.columnSearchKeys.mobile_last_login_at.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #mobile_last_used_at="{ item }">
            <td class="text-center">
              {{ item.mobile_last_used_at ? item.mobile_last_used_at : "None" }}
            </td>
          </template>
          <template #mobile_last_used_at-filter>
            <input
              v-model="state.columnSearchKeys.mobile_last_used_at.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #kiosk_pin="{ item }">
            <td class="mw-auto lh-normal text-center">
              {{
                item.kiosk_users && item.kiosk_users.kpasscode === "Y"
                  ? "Y"
                  : ""
              }}
            </td>
          </template>
        </DataTable>

        <Pagination
          v-if="
            state.pagination &&
            state.pagination.pages &&
            state.pagination.pages > 1 &&
            !state.isLazyLoadingMode
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="state.pagination.activePage"
          :pages="state.pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        >
          <template #last-button>
            <i class="flaticon-right-arrow-1"></i>
          </template>
          <template #first-button>
            <i class="flaticon-left-arrow-2"></i>
          </template>
          <template #previous-button>
            <i class="fi flaticon-left-arrow-1"></i>
          </template>
          <template #next-button>
            <i class="fi flaticon-chevron"></i>
          </template>
        </Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader"
import moment from "moment-timezone"
import PerPageSelector from "@/v3components/PerPageSelector.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton"
import helpers from "@/helpers/index"
import download from "@/helpers/downloadCSV"
import ViewUserDetails from "@/v3components/ViewUserDetails"

export default {
  name: "UsersTable",
  components: {
    Loader,
    PerPageSelector,
    HelpCenterButton
  },
  emits: ["setUserEditable"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isActiveSyncBtn: null,
      isLazyLoadingMode: false,
      isLoading: false,
      isLoadingBtn: false,
      syncButtonText: "",
      bulkArchiveSuccessMessage:
        "Your request to bulk archive has begun. If the action can be executed users will move from the Main Users page to the Archived User page. This will take some time to complete.",
      activeAvatar: null,
      showArchivedUsersModal: null,
      areAllEntriesChecked: false,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      formData: {
        loginType: [
          {
            value: 1,
            label: "InHouse SSO"
          },
          {
            value: 10,
            label: "Google"
          },
          {
            value: 20,
            label: "Microsoft"
          },
          {
            value: 101,
            label: "ClassLink"
          },
          {
            value: 102,
            label: "Clever"
          },
          {
            value: 103,
            label: "GG4L"
          }
        ]
      },
      selectedUsers: [],
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      tableTabs: [
        {
          label: "Users",
          value: "users"
        }
      ],
      activeTableTab: {
        value: "users"
      },
      filterParams: {
        sort_query: "",
        search_query: "",
        student_sis_id: false
      },
      dataTableFields: [
        { key: "select", label: "", filter: false },
        { key: "new", label: "New", filter: false },
        { key: "action", label: "Action", filter: false },
        {
          key: "student_sis_id",
          filter: false,
          sorter: false,
          label: "Student number",
          _style: "min-width:150px"
        },
        { key: "first_name", _style: "min-width:120px", label: "First Name" },
        { key: "last_name", _style: "min-width:120px", label: "Last Name" },
        { key: "email", _style: "min-width:100px", label: "Email" },
        { key: "role_id", label: "Role" },
        { key: "grade_year", _style: "min-width:150px", label: "Grad Year" },

        {
          key: "web_last_login_at",
          label: "Web Last Login",
          _style: "min-width:180px"
        },
        {
          key: "mobile_last_login_at",
          label: "Mobile Last Login",
          _style: "min-width:180px"
        },
        {
          key: "mobile_last_used_at",
          label: "Mobile Last Used",
          _style: "min-width:180px"
        },

        { key: "status", label: "Status", filter: false },
        {
          key: "kiosk_pin",
          label: "Kiosk PC",
          _style: "min-width:100px",
          filter: false
        }
      ],
      columnSearchKeys: {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        role_id: {
          value: "",
          column: ["role_user.name"],
          sortBy: "role_user.name"
        },
        grade_year: {
          value: "",
          column: ["role_user.pivot.grade_year"],
          sortBy: "role_user.pivot.grade_year.raw"
        },
        auth_type: {
          value: "",
          column: ["auth_type_name"],
          sortBy: "auth_type"
        },
        web_last_login_at: {
          value: "",
          column: ["web_last_login_at"],
          sortBy: "web_last_login_at"
        },
        mobile_last_login_at: {
          value: "",
          column: ["mobile_last_login_at"],
          sortBy: "mobile_last_login_at"
        },
        mobile_last_used_at: {
          value: "",
          column: ["mobile_last_used_at"],
          sortBy: "mobile_last_used_at"
        }
      },
      allAuthTypes: [
        {
          id: 0,
          icon: "user",
          title: "Manual"
        },
        {
          id: 10,
          icon: "google",
          title: "Google",
          html: '<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>'
        },
        {
          id: 20,
          icon: "microsoft",
          title: "Microsoft",
          html: '<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>'
        },
        {
          id: 101,
          icon: "sso",
          title: "ClassLink"
        },
        {
          id: 102,
          icon: "clever",
          title: "Clever"
        },
        {
          id: 103,
          icon: "gg4l",
          title: "GG4L"
        }
      ]
    })

    const users = computed(() => store.getters["users/users"])

    const authProviders = computed(
      () => store.getters["schools/activeSchoolAuthProviders"]
    )

    const user = computed(() => store.getters["authentication/user"])

    const synchronization = computed(
      () => store.getters["users/synchronization"]
    )

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const isSuperAdmin = computed(() => {
      return user.value.role.name == "admin" && user.value.auth_type == 1001
    })

    const isSyncButtonDisabled = computed(() => {
      return (
        synchronization.value &&
        synchronization.value.status &&
        synchronization.value.status !== "DONE"
      )
    })

    const isActiveSyncVisible = computed(() => {
      return (
        isSuperAdmin.value &&
        !isSyncButtonDisabled.value &&
        synchronization.value &&
        synchronization.value.status
      )
    })

    const authTypes = computed(() => {
      return authProviders.value
        ? state.allAuthTypes.filter((el) => authProviders.value.includes(el.id))
        : null
    })

    const activeDataFields = computed(() => {
      return state.dataTableFields.filter((field) =>
        !state.filterParams.student_sis_id
          ? field.key !== "student_sis_id"
          : field
      )
    })

    const provideUsersByApiOnly = computed(() => {
      return (
        activeSchool.value && activeSchool.value.provide_users_by_api_only == 1
      )
    })

    onMounted(() => {
      initLazyLoading()
      handleUsers()
      if (isSuperAdmin.value) {
        getSynchronization()
      }
    })

    const syncUsers = (id) => {
      state.isActiveSyncBtn = false
      state.syncButtonText = "Processing"
      state.isLoadingBtn = true
      store
        .dispatch("users/synchronization", id)
        .then(() => {
          getSynchronization()
          state.isLoadingBtn = true
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const getSynchronization = () => {
      store.dispatch("users/getSynchronization").then(() => {
        if (
          synchronization.value.status !== "DONE" &&
          synchronization.value.status !== "FAILED"
        ) {
          state.syncButtonText = "Processing"
          setTimeout(() => {
            getSynchronization()
          }, 30000)
        } else {
          state.isLoadingBtn = false
          state.isActiveSyncBtn = true
          state.syncButtonText = "Sync Users"
        }
      })
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            users.value &&
            users.value.length &&
            state.pagination.total > users.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleUsers(state.pagination.activePage, true)
            }
          }
        }
      }
    }

    const handleUsers = (page, isLazyLoadingMode, noLoader, isExport) => {
      state.isLoading = !noLoader
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value,
        archived: false
      }
      if (state.filterParams.student_sis_id) {
        params.student_sis_id = state.filterParams.student_sis_id
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (isExport) {
        exportUsers(params)
      } else {
        getUsers(params, isLazyLoadingMode)
      }
    }

    const getUsers = (params, isLazyLoadingMode) => {
      store
        .dispatch("users/getUsers", params)
        .then((response) => {
          const data = response.data
          if (isLazyLoadingMode) {
            store.commit("users/PUSH_USERS", data.data)
          } else {
            store.commit("users/SET_USERS", data.data)
          }
          const meta = data.meta
          if (meta) {
            state.pagination.total = meta.total
            state.pagination.from = meta.from
            state.pagination.to = meta.to
            state.pagination.pages = Math.ceil(meta.total / meta.per_page)
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const selectUsers = (e) => {
      if (e.target.checked) {
        state.selectedUsers = []
        users.value.forEach((user) => {
          state.selectedUsers.push(user.id)
        })
      } else {
        state.selectedUsers = []
      }
    }

    const inviteBulk = () => {
      store
        .dispatch("users/inviteBulk", { users: state.selectedUsers })
        .then(() => {
          handleUsers()
          state.selectedUsers = []
        })
    }

    const archive = () => {
      if (Object.keys(state.selectedUsers).length == 1) {
        store
          .dispatch("users/archiveSingle", state.selectedUsers[0])
          .then(() => {
            setTimeout(() => {
              handleUsers()
              setSuccessResponse("Successfully archived!")
              state.isLoading = false
              state.showArchivedUsersModal = true
            }, 500)
            state.selectedUsers = []
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            state.isLoading = false
            state.showArchivedUsersModal = true
          })
      } else {
        store
          .dispatch("users/archiveBulk", { users: state.selectedUsers.join() })
          .then(() => {
            setTimeout(() => {
              handleUsers()
            }, 500)
            state.selectedUsers = []
            setSuccessResponse(state.bulkArchiveSuccessMessage)
            state.isLoading = false
            state.showArchivedUsersModal = true
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            state.isLoading = false
            state.showArchivedUsersModal = true
          })
      }
    }

    const exportUsers = (params) => {
      state.isLoading = true
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("users/exportUsers", {
          ...params,
          is_substitute: false,
          is_archived: false,
          all_entries: state.areAllEntriesChecked,
          student_sis_id: true
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "users")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const onViewUser = (user) => {
      modal.open(ViewUserDetails, {
        size: "xl",
        props: {
          user
        }
      })
    }

    const editUser = (user) => {
      emit("setUserEditable")
      store.commit("users/SET_EDITABLE_USER", user)
      const topPosition = document.getElementById("user-manage-form-container")
        .offsetParent.offsetTop
      helpers.scrollToTop(topPosition ? topPosition - 100 : 0)
    }

    const recreateUser = (user) => {
      store
        .dispatch("users/recreateUser", user)
        .then(() => {
          resetForm()
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handleUsers()
    }

    const isCreatedToday = (date) => {
      return moment(date).isSame(moment(), "d")
    }

    const getAuthType = (id) => {
      switch (id) {
        case 1:
          return "Manual"
        case 10:
          return "Google"
        case 20:
          return "Microsoft"
        case 101:
          return "ClassLink"
        case 102:
          return "Clever"
        case 103:
          return "GG4L"
        default:
          return ""
      }
    }

    const searchByQuery = (value, type, minLetters) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type, minLetters)
      }, 500)
    }

    const handleQuerySearch = (value, type, minLetters) => {
      const query = value.toString()
      const minLen = minLetters ? parseInt(minLetters) : 0
      if (
        query.replace(/\s/g, "").length > minLen ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  if (
                    col == "role_user.name" ||
                    col == "role_user.pivot.grade_year"
                  ) {
                    state.filterParams.search_query =
                      state.filterParams.search_query +
                      `role_user.pivot.school_id:${user.value.school_id}, `
                  }
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? `"${query}"` : query
        }
        handleUsers(null, false)
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        role_id: {
          value: "",
          column: ["role_user.name"],
          sortBy: "role_user.name"
        },
        grade_year: {
          value: "",
          column: ["role_user.pivot.grade_year"],
          sortBy: "role_user.pivot.grade_year.raw"
        },
        auth_type: {
          value: "",
          column: ["auth_type_name"],
          sortBy: "auth_type"
        },
        web_last_login_at: {
          value: "",
          column: ["web_last_login_at"],
          sortBy: "web_last_login_at"
        },
        mobile_last_login_at: {
          value: "",
          column: ["mobile_last_login_at"],
          sortBy: "mobile_last_login_at"
        },
        mobile_last_used_at: {
          value: "",
          column: ["mobile_last_used_at"],
          sortBy: "mobile_last_used_at"
        }
      }
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.areAllEntriesChecked = option.label === "All entries"
        state.pagination.per_page = option
        handleUsers()
      }
    }

    const matchAuthType = (value) => {
      if (!value.length) {
        state.columnSearchKeys.auth_type.value = ""
        searchByQuery("", "column")
        return
      }
      const authType = state.formData.loginType.filter((loginType) =>
        loginType.label.includes(value)
      )
      if (authType.length) {
        state.columnSearchKeys.auth_type.value = authType[0].value
        searchByQuery(authType[0].value, "column")
      } else {
        state.columnSearchKeys.auth_type.value = ""
        searchByQuery("", "column")
      }
    }

    const refreshDataTable = () => {
      resetSearchQuery()
      handleUsers(1, false, true)
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleUsers(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const getUserStatus = (user) => {
      if (!user.is_synced) {
        return false
      } else if (user.invited_at == null && user.send_invite == 1) {
        return "IN PROGRESS"
      } else if (user.invited_at != null && user.send_invite == 0) {
        return "INVITED"
      } else if (isCreatedToday(user.created_at) && user.is_synced) {
        return "ADDED"
      }
      return null
    }

    const showAvatar = (avatar) => {
      state.activeAvatar = avatar
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const changeSNFilter = () => {
      state.filterParams.student_sis_id = !state.filterParams.student_sis_id
      handleUsers(state.pagination.activePage)
    }

    return {
      state,
      users,
      authProviders,
      user,
      synchronization,
      userRole,
      activeSchool,
      isSuperAdmin,
      isSyncButtonDisabled,
      isActiveSyncVisible,
      authTypes,
      activeDataFields,
      provideUsersByApiOnly,
      syncUsers,
      getSynchronization,
      initLazyLoading,
      handleUsers,
      getUsers,
      selectUsers,
      inviteBulk,
      archive,
      exportUsers,
      editUser,
      recreateUser,
      sortByColumn,
      isCreatedToday,
      getAuthType,
      searchByQuery,
      handleQuerySearch,
      resetSearchQuery,
      setPerPage,
      matchAuthType,
      refreshDataTable,
      setActivePage,
      getUserStatus,
      showAvatar,
      resetResponseMessages,
      setSuccessResponse,
      setErrorResponse,
      onViewUser,
      changeSNFilter
    }
  }
}
</script>
